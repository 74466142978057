// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
import "@fortawesome/fontawesome-free/js/all"
Rails.start()
import jquery from "jquery"
window.$ = window.jquery = jquery
import "bootstrap"
import "select2"
import "daterangepicker"
import moment from 'moment'
window.moment = moment
require("adminlte.min")
require("jquery.inputmask")
require("bootstrap-colorpicker.min")
require("jquery-ui")
global.toastr = require("toastr")
require("@nathanvda/cocoon")

$(function () {
  var remoteLinks = document.querySelectorAll('a[data-remote=\'true\']');

  for (var i = 0; i < remoteLinks.length; i++) {
    var link = remoteLinks[i];
    link.dataset.url = link.href;
    link.setAttribute('href', 'javascript:void(0);');
  }

  $.rails.href = function(element) {
    if (element.dataset) {
      var url = element.dataset.url || element.dataset.href;
      if (url) { return url; }
    }
    return element.href;
  }
});


